(function (global){
'use strict';

/* theme.js -- Components for theme / master template related items such as Main Navigation, Header interactions, and Footer interactions */

var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');
var topNavigation = require('./components/topNavigation');
var backToTop = require('./components/backToTop');
var foucManager = require('./components/foucManager');

// Register event handlers here (like jQuery load)
function _registerEventHandlers() {

    $(document).ready(function () {

        // Setup components
        topNavigation.initialize();
        backToTop.initialize();
        foucManager.preventFouc();
        console.log("theme.js initialized");
    });

}

_registerEventHandlers();

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})