'use strict';

require('jquery.mmenu');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeNavigation
    * @description Setup the navigation for the site.  Event handlers, etc
    **/
    var initializeNavigation = function () {

        var $menu = $("#nc-mobile-menu").mmenu({
            //keyboardNavigation: {
            //    enable: true,
            //    enhance: true
            //},
            navbar: {
                title: ""
            },
            navbars: [{
                "position": "top",
                "content": ["<div class='search-box' data-results-page='test'><input type='text'name='q'class='header-search'placeholder='Search Here...'aria-label='Enter a search term here.'/><input type='submit' class='header-search-button' value=''/></div>"]
            }],
            searchfield: {
                add: false,
                search: false
            },
            screenReader: {
                aria: true,
                text: {
                    closeMenu: "Close menu",
                    closeSubmenu: "Close submenu",
                    openSubmenu: "Open submenu",
                    toggleSubmenu: "Toggle submenu"
                }
            },
            "extensions": [
                "position-right"
            ]
        });
        var API = $menu.data("mmenu");

        var $hamburger = $(".hamburger");
        $hamburger.on("click", function () {
            API.open();
        });

        var $close = $(".wrapper");
        $close.on("click", function () {
            API.close();
        });

        API.bind("open:finish", function () {
            setTimeout(function () {
                $hamburger.addClass("is-active");
            }, 100);
        });
        API.bind("close:finish", function () {
            setTimeout(function () {
                $hamburger.removeClass("is-active");
            }, 100);
        });

        //////////////////////////////////////////////////////////////////////////
        // Special case to make top parent menu item a clickable link.  PW:RB
        $(".nav-label").click(function () {
            document.location.href = this.parentElement.getAttribute("href").valueOf();
        });
        //////////////////////////////////////////////////////////////////////////
      

        ///////////////////////////////////////////////////////////////
        // Where to buy section search handler
        $(".where-to-buy-search-button").click(function (e) {
            
            if ($(".where-to-buy-search-wrapper input.where-to-buy-search").first().val()) {
                var searchBox = $(".where-to-buy-search-wrapper input.where-to-buy-search").first();

                if (searchBox.val().length) {
                    e.preventDefault();
                    window.location = "/where-to-buy/?postalCode=" + searchBox.val();
                }
            }

            return false;
        });


        ///////////////////////////////////////////////////////////////  
        // Search handler
        $("input.header-search-button").click(function (e) {
            return handleSearch(e);
        });

        $('.search-box input.header-search').keypress(function (e) {
            if (e.which === 13) { // Enter key pressed
                return handleSearch(e);
            }
        });

        function handleSearch(e) {

            var searchBox = $(".search-box input.header-search").last(); // assume desktop

            // if the mobile box has a value, it's it....
            if ($(".search-box input.header-search").first().val()) {
                searchBox = $(".search-box input.header-search").first();
            }

            if (searchBox.val().length) {
                e.preventDefault();
                window.location = "/search?q=" + searchBox.val();
            }
            return false;
        }
        ///////////////////////////////////////////////////////////////  

        console.log("topNavigation initialized");
    };

    // Return an object exposing the public items
    return {
        initialize: initializeNavigation
    };

}());

