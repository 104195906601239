'use strict';

module.exports = (function () {

    /**
    * @ngdoc method
    * @name preventFouc
    * @description Removes all .fouc
    **/
    var preventFouc = function () {
        $('.fouc').removeClass('fouc');
    };

    // Return an object exposing the public items
    return {
        preventFouc: preventFouc
    };

}());