'use strict';

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeBackToTop
    * @description Setup addThis customizations
    **/
    var initializeBackToTop = function () {
        //SMOOTH SCROLL
        // BUG: SEE [NC41]: this selector was causing issues - simplifying it resolved the bootstrap error but unknown if this breaks what it was intended to fix...? PW:RB
        // $('a.backToTop[href*="#"]:not([href="#"])').click(function () {
        $('a.backToTop').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });

        //BACK TO TOP
        $(window).scroll(function () {
            if ($(this).scrollTop() > 1040 && !$('a.backToTop').hasClass('btt-in')) {
                $('a.backToTop').addClass('btt-in');
            } else if ($(this).scrollTop() <= 1040 && $('a.backToTop').hasClass('btt-in')) {
                $('a.backToTop').removeClass('btt-in');
            }
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializeBackToTop
    };

}());

